<template>
  <div>
    <BAlert
      variant="danger"
      :show="hasLoadError"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No campaign found with this product id. Check
        <BLink
          class="alert-link"
          :to="{ name: 'commerce-product-list'}"
        >
          Product List
        </BLink>
        for other products.
      </div>
    </BAlert>
    <BRow>
      <BCol cols="9">
        <BTabs>
          <BTab
            title="상품"
            :active="tabStage === 'product'"
            @click="setTabStage('product')"
          >
            <ProductInfoCard
              :product="product"
              :categoryOptions="categoryOptions"
            />
          </BTab>
          <BTab
            title="가격"
            :active="tabStage === 'price'"
            @click="setTabStage('price')"
          >
            <PriceInfoCard
              :product="product"
              :vat="vat"
            />
          </BTab>
          <BTab
            title="배송"
            :active="tabStage === 'delivery'"
            @click="setTabStage('delivery')"
          >
            <DeliveryInfoCard
              :product="product"
              :additionalShippingFeeCond="additionalShippingFeeCond"
              :shippingFreeCond="shippingFreeCond"
              :shippingPolicy="shippingPolicy"
              @inputDeliveryDays="inputDeliveryDays"
            />
          </BTab>
          <BTab
            title="옵션"
            :active="tabStage === 'option'"
            @click="setTabStage('option')"
          >
            <OptionInputCard />
            <OptionsInfoCard />
          </BTab>
          <BTab
            title="셀러"
            :active="tabStage === 'seller'"
            @click="setTabStage('seller')"
          >
            <SellerTableCard />
          </BTab>
          <BTab
            title="기타"
            :active="tabStage === 'etc'"
            @click="setTabStage('etc')"
          >
            <AbroadInfoCard
              :product="product"
              :abroadJson="abroadJson"
            />
            <VendorInfoCard
              :vendor="vendor"
            />
          </BTab>
        </BTabs>
      </BCol>
      <BCol cols="3">
        <ActionCard
          :product="product"
          :isSaving="isSavingProduct"
          :isSavingStock="isSavingStock"
          :isSavingOption="isSavingOption"
          :processingImageCrop="processingImageCrop"
          @save="updateProduct"
          @saveStock="updateStock"
          @saveOption="updateNewOption"
          @turnOnMemo="turnOnMemo"
          @turnOnVendorChangeSidebar="turnOnVendorChangeSidebar"
          @turnOnShippingPolicyChangeSidebar="turnOnShippingPolicyChangeSidebar"
          @turnOnShippingPolicyDeleteModal="turnOnShippingPolicyDeleteModal"
          @wakeUpImageCropModal="wakeUpImageCropModal"
        />
        <TagbyTimelinesV3
          :timelineList="state.timelineList"
        />
      </BCol>
    </BRow>

    <TagbyMemo
      :visible="visibleMemo"
      :content="memoContent"
      :isSaving="isSavingMemo"
      @hidden="turnOffMemo"
      @input="inputMemoContent"
      @save="createMemo"
    />

    <VendorChangeSidebar
      :visible="visibleVendorChangeSidebar"
      :vendorText="vendorText"
      :vendorList="vendorList"
      :selectedVendor="selectedVendor"
      :isValid="isValidVendorChange"
      @hidden="turnOffVendorChangeSidebar"
      @selectVendor="selectVendor"
      @inputVendorText="inputVendorText"
      @ok="changeVendor"
    />

    <ShippingPolicyChangeSidebar
      :visible="visibleShippingPolicyChangeSidebar"
      :shippingPolicyList="shippingPolicyList"
      @ok="changeShippingPolicy"
      @hidden="turnOffShippingPolicyChangeSidebar"
    />

    <ShippingPolicyDeleteModal
      :visible="visibleShippingPolicyDeleteModal"
      @ok="deleteShippingPollicy"
      @hidden="turnOffShippingPolicyDeleteModal"
    />

    <ImageCropModal
      ref="imageCropModal"
      @ok="changeImageUrl"
    />

    <OptionUpdateSidebar
      :visible="visibleOptionUpdateSidebar"
      :selectedOption="selectedOption"
      :isSaving="isSavingOptionUpdate"
      :isValid="isValidOptionUpdate"
      @ok="updateOption"
      @hidden="turnOffOptionUpdateSidebar"
    />

    <OptionDeleteModal />
    <AddMystoreSidebar />
    <Sidebars />
    <Modals />
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue"
import { defineComponent } from "@vue/composition-api"
import {
  useDetail,
  useInitialize,
} from "./viewModel"
import router from "@/router"
import TagbyTimelinesV3 from "@/components/TagbyTimelinesV3.vue"
import TagbyMemo from "@/components/TagbyMemo.vue"
import ActionCard from "./components/ActionCard.vue"
import ProductInfoCard from "./components/ProductInfoCard.vue"
import PriceInfoCard from "./components/PriceInfoCard.vue"
import DeliveryInfoCard from "./components/DeliveryInfoCard.vue"
import OptionsInfoCard from "./components/OptionsInfoCard.vue"
import AbroadInfoCard from "./components/AbroadInfoCard.vue"
import VendorInfoCard from "./components/VendorInfoCard.vue"
import VendorChangeSidebar from "./components/VendorChangeSidebar.vue"
import ShippingPolicyChangeSidebar from "./components/ShippingPolicyChangeSidebar.vue"
import ShippingPolicyDeleteModal from "./components/ShippingPolicyDeleteModal.vue"
import ImageCropModal from "./components/ImageCropModal.vue"
import OptionUpdateSidebar from "./components/OptionUpdateSidebar.vue"
import OptionDeleteModal from "./components/OptionDeleteModal.vue"
import OptionInputCard from "./components/OptionInputCard.vue"
import SellerTableCard from "./components/SellerTableCard.vue"
import AddMystoreSidebar from "./components/AddMystoreSidebar.vue"
import { getRouterIdx } from "@/utils/router"
import state from "./state"
import useState from "./hooks/useState"
import useFetch from "./hooks/useFetch"
import Sidebars from "./components/Sidebars"
import Modals from "./components/Modals"

export default defineComponent({
  components: {
    BAlert,
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,

    TagbyTimelinesV3,
    TagbyMemo,

    ActionCard,
    ProductInfoCard,
    PriceInfoCard,
    DeliveryInfoCard,
    OptionsInfoCard,
    AbroadInfoCard,
    VendorInfoCard,
    VendorChangeSidebar,
    ShippingPolicyChangeSidebar,
    ShippingPolicyDeleteModal,
    ImageCropModal,
    OptionUpdateSidebar,
    OptionDeleteModal,
    OptionInputCard,
    SellerTableCard,
    AddMystoreSidebar,
    Sidebars,
    Modals,
  },
  setup() {
    const { resetState } = useState()
    resetState()

    state.idx = getRouterIdx()

    const { initialize } = useInitialize()
    initialize()

    const detail = useDetail()
    const {
      fetchDetail,
      fetchCategories,
      tabStage,
      setTabStage,
    } = detail

    fetchDetail()
    tabStage.value = router.history.current.query.tab
    if (!tabStage.value) {
      setTabStage("product")
    }
    fetchCategories()

    const {
      getTimelineList,
    } = useFetch()
    getTimelineList()

    return {
      state,
      ...detail,
    }
  },
})
</script>

import state from "../state"

export default () => {
  const turnOnModal = err => {
    state.errorReasonModal.isVisible = true
    if (!err.response) {
      state.errorReasonModal.errorReason = err.toString()
      return
    }
    if (!err.response.data) {
      state.errorReasonModal.errorReason = err.toString()
      return
    }
    const errorData = err.response.data
    if (typeof errorData === "string") {
      state.errorReasonModal.errorReason = errorData
      return
    }
    state.errorReasonModal.errorReason = JSON.stringify(errorData, null, 4)
  }
  const turnOffModal = () => {
    state.errorReasonModal.isVisible = false
  }

  return {
    turnOnModal,
    turnOffModal,
  }
}

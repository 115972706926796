<template>
  <div>
    <BCard title="상품 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="IDX">
            <BFormInput
              :value="product.idx"
              readonly
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="상품명">
            <BFormInput
              v-model="product.name"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="브랜드명">
            <BFormInput
              v-model="product.brand_name"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="카테고리">
            <vSelect
              v-model="product.category_idx"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="제품번호">
            <TagbyCopyableInput
              v-model="product.product_no"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="태그바이품번">
            <TagbyCopyableInput
              v-model="product.tagby_product_no"
              disabled
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="재고">
            <BInputGroup
              v-if="getters.hasOption"
              id="product_stock"
              append="개"
              class="input-group-merge"
            >
              <BTooltip
                target="product_stock"
                triggers="hover"
                variant="info"
              >
                옵션 재고들의 합
              </BTooltip>
              <BFormInput
                :value="computedOptionStock"
                readonly
              />
            </BInputGroup>
            <BInputGroup
              v-else
              append="개"
            >
              <BInputGroupPrepend>
                <BButton
                  variant="outline-primary"
                  :disabled="state.isSavingProductStock"
                  @click="updateProductStock"
                >
                  <BSpinner
                    v-if="state.isSavingProductStock"
                    small
                  />
                  저장
                </BButton>
              </BInputGroupPrepend>
              <BFormInput
                v-model="product.stock"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="판매 타입">
            <vSelect
              v-model="product.sell_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sellTypeOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="판매 상태">
            <vSelect
              v-model="product.state"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="stateOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="출시일">
            <TagbyDatetimeInputV2
              v-model="product.start_at"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="판매종료일">
            <TagbyDatetimeInputV2
              v-model="product.end_at"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="썸네일">
            <TagbyPopoverImg
              :url="product.thumbnail_img_url"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="상세설명">
            <TagbyEditor v-model="product.detail_html_content" />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="상품정보">
            <BFormTextarea
              v-model="product.description"
              rows="5"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BImg,
  BTooltip,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue"
import { defineComponent, computed } from "@vue/composition-api"
import vSelect from "vue-select"
import flatPickr from "vue-flatpickr-component"
import TagbyCopyableInput from "@/components/TagbyCopyableInput.vue"
import TagbyLinkInput from "@/components/TagbyLinkInput.vue"
import TagbyDatetimeInputV2 from "@/components/TagbyDatetimeInputV2.vue"
import TagbyPopoverImg from "@/components/TagbyPopoverImg.vue"
import TagbyEditor from "@/components/TagbyEditor.vue"
import state from "../state"
import getters from "../getters"
import useUpdateStock from "../hooks/useUpdateStock"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BImg,
    BTooltip,
    BFormTextarea,
    BSpinner,

    vSelect,
    flatPickr,
    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyDatetimeInputV2,
    TagbyPopoverImg,
    TagbyEditor,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    categoryOptions: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    const sellTypeOptions = [
      { label: "상시판매", value: "ALWAYS" },
      { label: "모집판매", value: "RECRUITMENT" },
    ]
    const stateOptions = [
      { label: "판매중", value: "ACTIVE" },
      { label: "승인대기중", value: "CONFIRMING" },
      { label: "매진", value: "SOLDOUT" },
      { label: "숨김", value: "HIDDEN" },
      { label: "삭제", value: "INACTIVE" },
      { label: "삭제요청중", value: "DELETING" },
      { label: "작성중", value: "WRITING" },
    ]

    const computedOptionStock = computed(() => state.optionList.reduce((p, c) => p + c.stock, 0))

    const {
      updateProductStock,
    } = useUpdateStock()
    return {
      sellTypeOptions,
      stateOptions,
      computedOptionStock,
      state,
      getters,
      updateProductStock,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

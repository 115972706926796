<template>
  <div v-if="state.optionType !== 'NO'">
    <BCard
      title="옵션 정보"
      no-body
    >
      <BTable
        ref="optionsTableRef"
        :fields="fields"
        :items="options"
        small
        selectable
        selected-variant=""
      >
        <template #cell(selected)="row">
          <BFormCheckbox
            v-model="row.rowSelected"
            class="vs-checkbox-con"
            @change="toggleTableChecked(row)"
          />
        </template>

        <template #cell(add_supply_price)="data">
          <BInputGroup
            append="원"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.add_supply_price"
              type="number"
              number
              @input="inputOption(data.item.idx, 'add_supply_price', $event)"
            />
          </BInputGroup>
        </template>

        <template #cell(add_price)="data">
          <BInputGroup
            append="원"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.add_price"
              type="number"
              number
              @input="inputOption(data.item.idx, 'add_price', $event)"
            />
          </BInputGroup>
        </template>

        <template #cell(add_tagby_fee)="data">
          <BInputGroup
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.add_tagby_fee"
              type="number"
              number
              @input="inputOption(data.item.idx, 'add_tagby_fee', $event)"
            />
            <BInputGroupAppend>
              <BInputGroupText>
                원
              </BInputGroupText>
              <BButton
                variant="outline-secondary"
                @click="calculateAddTagbyFee(data.item.idx)"
              >
                자동계산
              </BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </template>

        <template #cell(stock)="data">
          <BInputGroup
            append="개"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.stock"
              type="number"
              number
              @input="inputOption(data.item.idx, 'stock', $event)"
            />
          </BInputGroup>
        </template>

        <template #cell(save)="data">
          <BButton
            variant="outline-primary"
            small
            :disabled="data.item.is_saving || hasUpdatedOptionRow"
            @click="updateOptionLeaf(data.item)"
          ><FeatherIcon icon="SaveIcon" /></BButton>
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BInputGroup,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroupText,
} from "bootstrap-vue"
import {
  defineComponent,
  computed,
} from "@vue/composition-api"
import {
  useProductOption,
  useDeleteOptionModal,
  useUpdateOptionLeaf,
  useUpdateOption,
} from "../viewModel"
import state from "../state"

export default defineComponent({
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
  },
  setup() {
    const {
      options,
      optionsTableRef,
      inputOption,
      calculateAddTagbyFee,
    } = useProductOption()

    const fields = computed(() => [
      { key: "selected", label: "" },
      ...state.optionMeta?.categories ?? [],
      { key: "add_supply_price", label: "추가공급가" },
      { key: "add_price", label: "추가가격" },
      { key: "add_tagby_fee", label: "추가태그바이수수료" },
      { key: "stock", label: "재고수량" },
      { key: "save", label: "개별저장" },
    ])

    // const rowClass = (item, type) => {
    //   if (!item || type !== 'row') return null
    //   if (item.state === 'INACTIVE') return 'table-danger'
    //   return null
    // }
    const test = () => {
      console.log(options.value)
    }

    const toggleTableChecked = row => {
      const i = options.value.findIndex(item => item.idx === row.item.idx)
      if (row.rowSelected) {
        optionsTableRef.value.selectRow(i)
      } else {
        optionsTableRef.value.unselectRow(i)
      }
    }

    const {
      turnOn: turnOnDeleteOptionModal,
    } = useDeleteOptionModal()

    const {
      updateOptionLeaf,
    } = useUpdateOptionLeaf()

    const {
      hasUpdatedOptionRow,
    } = useUpdateOption()

    return {
      state,
      options,
      fields,
      // rowClass,
      optionsTableRef,
      inputOption,
      test,
      turnOnDeleteOptionModal,
      toggleTableChecked,
      calculateAddTagbyFee,

      updateOptionLeaf,
      hasUpdatedOptionRow,
    }
  },
})
</script>

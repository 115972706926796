<template>
  <BCard title="셀러 리스트">
    <BTable
      :items="sellers"
      :fields="fields"
      small
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'member-inf-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(mystore_idx)="data">
        <BLink :to="{ name: 'commerce-mystore-view', params: { idx: data.item.myshop.idx } }">
          {{ data.item.myshop.idx }}
        </BLink>
      </template>

      <template #cell(mystore_name)="data">
        {{ data.item.myshop.name }}
      </template>

      <template #cell(mystore_link)="data">
        <BLink
          :href="resolveMystoreLink(data.item.myshop.url_path)"
          target="_blank"
        >
          {{ resolveMystoreLink(data.item.myshop.url_path) }}
        </BLink>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import { useSellerTable } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
  },
  setup() {
    const {
      sellers,
      fields,
      fetchSellerList,
      resolveMystoreLink,
    } = useSellerTable()

    fetchSellerList()

    return {
      sellers,
      fields,
      resolveMystoreLink,
    }
  },
})
</script>

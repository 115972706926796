<template>
  <BSidebar
    bg-variant="white"
    right
    backdrop
    shadow
    no-header
    :visible="isVisible"
    :width="width"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
  >
    <template #default="{hide}">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          <slot name="title">
            {{ title }}
          </slot>
        </h5>

        <FeatherIcon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="p-1">
        <slot />
        <BRow v-if="!noButton">
          <BCol>
            <BButton
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid"
              @click="$emit('ok')"
            >
              <BSpinner
                v-if="isSaving"
                small
              />
              {{ okText }}
            </BButton>
            <BButton
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              {{ cancelText }}
            </BButton>
          </BCol>
        </BRow>
      </div>
    </template>
  </BSidebar>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BSidebar,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from "bootstrap-vue"

export default defineComponent({
  components: {
    BSidebar,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  emits: [
    "hidden",
    "shown",
    "ok",
  ],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: "확인",
    },
    cancelText: {
      type: String,
      default: "취소",
    },
    noButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>

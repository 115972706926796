import axiosFrame from "@/utils/axiosFrame"

export default {
  getAdminMe: axiosFrame("get", "/manage/admin/me/", null),
  getTimelineList: axiosFrame("get", "/manage/history/timeline-list/", null),
  createMemo: axiosFrame("post", "/manage/memo/create-memo/", null),
  updateOption: axiosFrame("post", "/manage/product-re/update-option/", null),
  updateOptionLeaf: axiosFrame("post", "/manage/product-re/update-option-leaf/", null),
  getOptionList: axiosFrame("get", "/manage/product-re/option-list/", null),
  updateProductStock: axiosFrame("post", "/manage/product-re/update-product-stock/", null),
}

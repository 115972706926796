<template>
  <div>
    <ErrorReasonModal />
    <UpdateOption />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import ErrorReasonModal from "./ErrorReasonModal.vue"
import UpdateOption from "./UpdateOption.vue"

export default defineComponent({
  components: {
    ErrorReasonModal,
    UpdateOption,
  },
  setup() {

  },
})
</script>

<template>
  <TagbySidebar
    :visible="visible"
    @hidden="$emit('hidden')"
  >
    <template #title>
      옵션 수정
    </template>
    <template #content>
      <BFormGroup label="추가공급가">
        <BInputGroup
          append="원"
          class="input-group-merge"
        >
          <BFormInput
            v-model="selectedOption.add_supply_price"
            number
            type="number"
          />
        </BInputGroup>
      </BFormGroup>
      <BFormGroup label="추가가격">
        <BInputGroup
          append="원"
          class="input-group-merge"
        >
          <BFormInput
            v-model="selectedOption.add_price"
            number
            type="number"
          />
        </BInputGroup>
      </BFormGroup>
      <BFormGroup label="추가태그바이수수료">
        <BInputGroup
          append="원"
          class="input-group-merge"
        >
          <BFormInput
            v-model="selectedOption.add_tagby_fee"
            number
            type="number"
          />
        </BInputGroup>
      </BFormGroup>
      <BFormGroup label="재고수량">
        <BInputGroup
          append="개"
          class="input-group-merge"
        >
          <BFormInput
            v-model="selectedOption.stock"
            number
            type="number"
          />
        </BInputGroup>
      </BFormGroup>
      <BRow>
        <BCol>
          <BButton
            variant="outline-primary"
            class="mr-1"
            :disabled="!isValid"
            @click="$emit('ok', {...selectedOption})"
          >
            <BSpinner
              v-if="isSaving"
              small
            />
            확인
          </BButton>
          <BButton
            variant="outline-secondary"
            @click="$emit('hidden')"
          >
            취소
          </BButton>
        </BCol>
      </BRow>
    </template>
  </TagbySidebar>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default defineComponent({
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,

    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: Object,
      default: () => ({}),
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>

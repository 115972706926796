<template>
  <div>
    <TagbySidebar
      :visible="visible"
      width="30%"
      @hidden="$emit('hidden')"
    >
      <template #title>
        합배송그룹 변경
      </template>
      <template #content>
        <BRow>
          <BTable
            :fields="fields"
            :items="shippingPolicyList"
            small
          >
            <template #cell(jeju)="data">
              {{ data.item.additional_shipping_fee_cond.jeju }}
            </template>

            <template #cell(island_mountain)="data">
              {{ data.item.additional_shipping_fee_cond.island_mountain }}
            </template>

            <template #cell(select)="data">
              <BButton
                variant="flat-primary"
                size="sm"
                @click="turnOnModal(data.item)"
              >
                선택
              </BButton>
            </template>
          </BTable>
        </BRow>
        <BRow>
          <BCol>
            <BButton
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </BButton>
          </BCol>
        </BRow>
      </template>
    </TagbySidebar>
    <BModal
      ref="shippingPolicyChangeModal"
      title="합배송그룹 변경"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      centered
      @ok="$emit('ok', selectedItem)"
      @hidden="turnOffModal"
    >
      <span v-if="selectedItem != null">
        합배송그룹 {{ selectedItem.name }}로 변경하시겠습니까?
      </span>
    </BModal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BTable,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BTable,

    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shippingPolicyList: {
      type: Array,
      default: () => ([]),
    },
  },
  setup() {
    const selectedItem = ref()
    const shippingPolicyChangeModal = ref()
    const turnOnModal = item => {
      selectedItem.value = item
      shippingPolicyChangeModal.value.show()
    }
    const turnOffModal = () => {
      selectedItem.value = null
    }
    const fields = [
      { key: 'name', label: '그룹명' },
      { key: 'type', label: '계산방식' },
      { key: 'jeju', label: '제주' },
      { key: 'island_mountain', label: '도서산간' },
      { key: 'select', label: '선택' },
    ]

    const test = value => { console.log(value) }
    return {
      fields,
      selectedItem,
      shippingPolicyChangeModal,
      turnOnModal,
      turnOffModal,
      test,
    }
  },
}
</script>

<template>
  <div>
    <OptionMeta
      v-for="(category, i) in state.manageOption.optionCategories"
      :key="i"
      :index="i"
      :category="category"
      :names="state.manageOption.optionNames[i]"
      :visibleMinus="getVisibleMinus(i)"
      :visiblePlus="getVisiblePlus(i)"
    />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import OptionMeta from "./OptionMeta.vue"
import { useInputOption } from "../viewModel"
import state from "../state"

export default defineComponent({
  components: {
    OptionMeta,
  },
  setup() {
    const {
      getVisibleMinus,
      getVisiblePlus,
    } = useInputOption()
    return {
      state,
      getVisibleMinus,
      getVisiblePlus,
    }
  },
})
</script>

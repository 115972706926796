<template>
  <BCard title="옵션 입력">
    <BRow>
      <BCol>
        <BFormRadioGroup
          v-model="state.optionType"
          :options="optionTypeList"
        />
      </BCol>
    </BRow>
    <BRow class="mt-1">
      <BCol>
        <OptionMetaGroup v-if="state.optionType !== 'NO'" />
      </BCol>
    </BRow>
    <BRow>
      <BCol class="d-flex">
        <BButton
          variant="outline-danger"
          class="mr-1"
          @click="updateOptionsMeta"
        >
          새로 적용
        </BButton>

        <BButton
          variant="outline-danger"
          class="mr-1"
          :disabled="!hasSelectedRows"
          @click="turnOnDeleteOptionModal"
        >
          선택 삭제
        </BButton>

        <BButton
          variant="outline-primary"
          class="mr-1"
          :disabled="isSavingOption"
          @click="turnOnUpdateOptionModal"
        >
          <BSpinner
            v-if="isSavingOption"
            small
          />
          옵션 저장
        </BButton>

        <BButton
          variant="outline-primary"
          class="mr-1"
          :disabled="state.isGettingOption"
          @click="getOptionList"
        >
          <BSpinner
            v-if="state.isGettingOption"
            small
          />
          <FeatherIcon icon="RefreshCwIcon" />
        </BButton>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue"
import {
  useInputOption,
  useDeleteOptionModal,
  useUpdateOption,
  useProductOption,
} from "../viewModel"
import OptionMetaGroup from "./OptionMetaGroup.vue"
import state from "../state"
import useUpdateOptionModal from "../hooks/useUpdateOptionModal"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormInput,
    BButton,
    BSpinner,

    OptionMetaGroup,
  },
  setup() {
    const {
      optionTypeList,
      updateOptionsMeta,
    } = useInputOption()

    const {
      turnOn: turnOnDeleteOptionModal,
      hasSelectedRows,
    } = useDeleteOptionModal()

    const {
      hasUpdatedOptionRow,
      isSavingOption,
      updateNewOption,
    } = useUpdateOption()

    const {
      getOptionList,
    } = useProductOption()

    const {
      turnOnModal: turnOnUpdateOptionModal,
    } = useUpdateOptionModal()

    return {
      state,
      optionTypeList,
      hasSelectedRows,
      hasUpdatedOptionRow,
      isSavingOption,
      updateOptionsMeta,
      turnOnDeleteOptionModal,
      updateNewOption,
      getOptionList,
      turnOnUpdateOptionModal,
    }
  },
})
</script>

import api from "../api"
import state from "../state"
import {
  useDetail,
} from "../viewModel"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import useErrorReasonModal from "./useErrorReasonModal"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    product,
    fetchDetail,
  } = useDetail()

  const {
    getTimelineList,
  } = useFetch()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReasonModal()

  const updateProductStock = () => {
    state.isSavingProductStock = true
    return api.updateProductStock({
      product_idx: state.idx,
      before_stock: state.beforeStock,
      after_stock: product.value.stock,
    }).then(() => {
      makeToast("primary", "상품 재고를 수정하는데 성공했습니다")
      getTimelineList()
    }).catch(err => {
      makeToast("danger", "상품 재고를 수정하는데 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      fetchDetail()
      state.isSavingProductStock = false
    })
  }

  return {
    updateProductStock,
  }
}

export default () => ({
  hasLoadError: false,
  adminPermission: null,
  timelineList: [],
  idx: null,
  beforeStock: null,
  product: {},
  optionList: [],
  optionMeta: null,
  optionType: null,
  newOptions: null,
  isSaving: false,
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
  beforeOptionList: [],
  createMemoSidebar: {
    isVisible: false,
    isSaving: false,
    text: null,
  },
  isGettingOption: false,
  isSavingProductStock: false,
  uploadOptionExcelSidebar: {
    isVisible: false,
    isSaving: false,
    isUploading: false,
    excelFile: null,
    jsonFormatData: null,
  },
  manageOption: {
    hasDeletedRows: false,
    optionCategories: [null],
    optionNames: [null],
  },
  updateOptionModal: {
    isVisible: false,
    isSaving: false,
    isUpdateStock: false,
  },
})

<template>
  <TagbyModal
    :isVisible="state.updateOptionModal.isVisible"
    :isBusy="state.updateOptionModal.isSaving"
    title="옵션저장"
    @hidden="turnOffModal"
    @ok="updateOption"
  >
    <div class="text-info">
      * 옵션이 많으면 저장이 오래걸릴수 있습니다.
    </div>
    <BFormGroup>
      <BFormCheckbox v-model="state.updateOptionModal.isUpdateStock">
        <div class="text-danger">
          재고도 같이 수정을 원한다면 체크해주세요.
        </div>
      </BFormCheckbox>
    </BFormGroup>
  </TagbyModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue"
import TagbyModal from "@/components/TagbyModal.vue"
import useUpdateOptionModal from "../../hooks/useUpdateOptionModal"
import state from "../../state"

export default defineComponent({
  components: {
    TagbyModal,
    BFormGroup,
    BFormCheckbox,
  },
  setup() {
    const {
      turnOffModal,
      updateOption,
    } = useUpdateOptionModal()

    return {
      state,
      turnOffModal,
      updateOption,
    }
  },
})
</script>

<template>
  <div>
    <BCard>
      <BRow>
        <BCol cols="6">
          <BButton
            v-b-tooltip.hover.v-info
            title="옵션과 재고를 제외한 나머지 값을 저장합니다"
            variant="outline-primary"
            block
            :disabled="isSaving"
            @click="$emit('save')"
          >
            <BSpinner
              v-if="isSaving"
              small
            />
            상품저장
          </BButton>

          <BButton
            v-b-tooltip.hover.v-info
            title="옵션 및 상품의 재고값 만을 저장합니다"
            variant="outline-primary"
            block
            :disabled="isSavingStock"
            @click="$emit('saveStock')"
          >
            <BSpinner
              v-if="isSavingStock"
              small
            />
            재고저장
          </BButton>

          <!-- <BButton
            variant="outline-primary"
            block
            :disabled="isSavingOption"
            @click="$emit('saveOption')"
          >
            <BSpinner
              v-if="isSavingOption"
              small
            />
            옵션새로저장
          </BButton> -->

          <BButton
            variant="outline-secondary"
            block
            @click="$emit('turnOnVendorChangeSidebar')"
          >
            벤더 변경
          </BButton>

          <BButton
            variant="outline-secondary"
            block
            :disabled="processingImageCrop"
            @click="$emit('wakeUpImageCropModal')"
          >
            <BSpinner
              v-if="processingImageCrop"
              small
            />
            썸네일 변경
          </BButton>

          <AddMystoreButton />
        </BCol>

        <BCol cols="6">
          <BButton
            variant="outline-secondary"
            block
            @click="$emit('turnOnMemo')"
          >
            메모
          </BButton>

          <BButton
            variant="outline-secondary"
            block
            @click="$emit('turnOnShippingPolicyChangeSidebar')"
          >
            합배송그룹 변경
          </BButton>

          <BButton
            variant="outline-secondary"
            block
            @click="$emit('turnOnShippingPolicyDeleteModal')"
          >
            합배송그룹 제거
          </BButton>

          <BButton
            variant="outline-secondary"
            block
            @click="turnOnUploadOptionExcelSidebar"
          >
            옵션엑셀 업로드
          </BButton>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue"
import AddMystoreButton from "./AddMystoreButton.vue"
import useUploadOptionExcelSidebar from "../hooks/useUploadOptionExcelSidebar"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,

    AddMystoreButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isSavingStock: {
      type: Boolean,
      default: false,
    },
    isSavingOption: {
      type: Boolean,
      default: false,
    },
    processingImageCrop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      turnOnSidebar: turnOnUploadOptionExcelSidebar,
    } = useUploadOptionExcelSidebar()

    return {
      turnOnUploadOptionExcelSidebar,
    }
  },
}
</script>

import { render, staticRenderFns } from "./OptionsInfoCard.vue?vue&type=template&id=4c311b10&"
import script from "./OptionsInfoCard.vue?vue&type=script&lang=js&"
export * from "./OptionsInfoCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
    <BCard title="해외 직배송 정보">
      <BRow>
        <BCol>
          <BFormGroup label="해외직배송여부">
            <BFormCheckbox v-model="product.is_abroad" />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow v-if="product.is_abroad">
        <BCol cols="4">
          <BFormGroup label="국가">
            <BFormInput v-model="abroadJson.nation" />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="성명">
            <BFormInput v-model="abroadJson.acceptor" />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="연락처">
            <BFormInput v-model="abroadJson.contact" />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow v-else>
        <BCol cols="4">
          <BFormGroup label="국가">
            <BFormInput readonly />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="성명">
            <BFormInput readonly />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="연락처">
            <BFormInput readonly />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    abroadJson: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {

  },
})
</script>

import state from "../state"
import {
  useUpdateOption,
} from "../viewModel"

export default () => {
  const {
    updateNewOption,
  } = useUpdateOption()

  const turnOnModal = () => {
    state.updateOptionModal.isVisible = true
  }

  const turnOffModal = () => {
    state.updateOptionModal.isVisible = false
  }

  const updateOption = () => {
    state.updateOptionModal.isSaving = true
    updateNewOption().finally(() => {
      state.updateOptionModal.isSaving = false
      turnOffModal()
    })
  }

  return {
    turnOnModal,
    turnOffModal,
    updateOption,
  }
}

<template>
  <div>
    <UploadOptionExcel />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import UploadOptionExcel from "./UploadOptionExcel.vue"

export default defineComponent({
  components: {
    UploadOptionExcel,
  },
  setup() {

  },
})
</script>

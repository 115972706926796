<template>
  <div>
    <BModal
      ref="imageCropModal"
      title="이미지 자르기"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      centered
      @ok="cropImage"
      @hidden="turnOffModal"
    >
      <VueCroppie
        ref="croppieRef"
        :enableResize="false"
        :enableOrientation="true"
        :boundary="{ width: '100%', height: 368 }"
      />
    </BModal>
    <input
      id="__image_file_upload_for_crop"
      type="file"
      accept="image/*"
      style="display:none"
    >
  </div>
</template>

<script>
import {
  BModal,
  BImg,
} from 'bootstrap-vue'
import {
  defineComponent, ref, onMounted, onUnmounted,
} from '@vue/composition-api'
import { v4 as uuid4 } from 'uuid'
import uploadFile from '@/utils/uploadFile'
import useToast from '@/utils/toast'

export default defineComponent({
  components: {
    BModal,
    BImg,
  },
  setup(props, { emit }) {
    const { makeToast } = useToast()
    const processing = ref(false)
    const wakeUp = () => {
      document.getElementById('__image_file_upload_for_crop').click()
    }
    const blobToFile = blob => {
      const name = `${uuid4()}.png`
      return new File(
        [blob],
        name,
        { type: 'image/png' },
      )
    }

    const croppieRef = ref()
    const croppie = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = ev => {
        croppieRef.value.bind({
          url: ev.target.result,
        })
      }
      reader.readAsDataURL(files[0])
    }
    const cropImage = () => {
      const options = {
        type: 'blob',
        size: { width: 700, height: 700 },
        format: 'png',
      }
      croppieRef.value.result(options, output => {
        processing.value = true
        const outputFile = blobToFile(output)
        uploadFile(outputFile, 'product_info', '')
          .then(response => {
            makeToast('primary', '이미지를 업로드하는데 성공했습니다')
            emit('ok', response.file_url)
          }).catch(() => {
            makeToast('danger', '이미지를 업로드하는데 실패했습니다')
          }).finally(() => {
            processing.value = false
          })
      })
    }

    const imageCropModal = ref()
    const turnOnModal = e => {
      imageCropModal.value.show()
      croppie(e)
    }
    const turnOffModal = () => {
      document.getElementById('__image_file_upload_for_crop').value = null
    }

    onMounted(() => {
      document.getElementById('__image_file_upload_for_crop').addEventListener('input', turnOnModal)
    })
    onUnmounted(() => {
      document.getElementById('__image_file_upload_for_crop').removeEventListener('input', turnOnModal)
    })

    return {
      processing,
      croppieRef,
      wakeUp,
      turnOffModal,
      imageCropModal,
      cropImage,
    }
  },
})
</script>

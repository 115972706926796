<template>
  <div>
    <BCard title="벤더 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="벤더 IDX">
            <TagbyLinkInput
              :value="vendor.idx"
              :to="{name: 'member-adv-view', params: {idx: vendor.idx}}"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="벤더 회사명">
            <BFormInput
              :value="vendor.company_name"
              readonly
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="벤더명">
            <BFormInput
              :value="vendor.name"
              readonly
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyLinkInput,
  },
  props: {
    vendor: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
  },
})
</script>

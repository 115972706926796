<template>
  <BButton
    variant="outline-info"
    block
    @click="turnOnSidebar"
  >
    마이스토어에 담기
  </BButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
} from 'bootstrap-vue'
import { useAddMystore } from '../viewModel'

export default defineComponent({
  components: {
    BButton,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useAddMystore()

    return {
      turnOnSidebar,
    }
  },
})
</script>

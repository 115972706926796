import {
  ref,
  computed,
  onUnmounted,
} from "@vue/composition-api"

import store from "@/store"
import router from "@/router"
import debounce from "@/utils/debounce"
import useToast from "@/utils/toast"
import useStore from "@/utils/store"
import useResolveValue from "@/utils/resolveValue"

import productStoreModule from "@/store/tagby/product"
import state from "./state"
import api from "./api"
import {
  bool,
} from "@/utils/value"
import useFetch from "./hooks/useFetch"

const product = ref()

export function useInitialize() {
  const initialize = () => {
    if (!store.hasModule(productStoreModule.STORE_MODULE_NAME)) {
      store.registerModule(productStoreModule.STORE_MODULE_NAME, productStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(productStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(productStoreModule.STORE_MODULE_NAME)
      }
    })
  }
  return {
    initialize,
  }
}

const sellers = ref([])
export function useSellerTable() {
  const { makeToast } = useToast()
  const { dispatch } = useStore(productStoreModule.STORE_MODULE_NAME)
  const fetchSellerList = () => {
    dispatch("fetchSellerList", {
      idx: router.currentRoute.params.idx,
    }).then(response => {
      sellers.value = response.data.data
    }).catch(() => {
      makeToast("danger", "셀러 목록을 받아 오는데 실패했습니다")
    }).finally(() => {
    })
  }
  const fields = [
    { key: "idx", label: "inf_idx" },
    { key: "name", label: "inf_name" },
    { key: "mystore_idx", label: "mystore_idx" },
    { key: "mystore_name", label: "mystore_name" },
    { key: "mystore_link", label: "mystore_link" },
  ]
  const resolveMystoreLink = urlPath => (urlPath == null ? null : `${process.env.VUE_APP_MYSTORE_URL}/${urlPath}`)
  return {
    sellers,
    fields,
    fetchSellerList,
    resolveMystoreLink,
  }
}

const visibleAddMystoreSidebar = ref(false)
export function useAddMystore() {
  const { makeToast } = useToast()
  const { dispatch } = useStore(productStoreModule.STORE_MODULE_NAME)
  const { getTimelineList } = useFetch()
  const { fetchSellerList } = useSellerTable()

  const searchText = ref()
  const myshopList = ref([])
  const selectedMyshop = ref()

  const turnOnSidebar = () => {
    visibleAddMystoreSidebar.value = true
  }
  const turnOffSidebar = () => {
    searchText.value = null
    myshopList.value = []
    selectedMyshop.value = null
    visibleAddMystoreSidebar.value = false
  }

  const myshopSuggestions = computed(() => ([{
    data: myshopList.value,
  }]))

  const fetchMyshopList = () => {
    dispatch("fetchMyshopList", {
      text: searchText.value,
    }).then(response => {
      myshopList.value = response.data.data
    }).catch(() => {
      makeToast("danger", "마이스토어 리스트를 가져오는데 실패했습니다")
    })
  }

  const debouncedFetchMyshopList = debounce(fetchMyshopList, 1000)

  const inputSearchText = value => {
    searchText.value = value
    selectedMyshop.value = null
    if (value.length >= 2) {
      debouncedFetchMyshopList()
    } else {
      myshopList.value = []
    }
  }

  const selectMyshop = data => {
    selectedMyshop.value = data.item
    searchText.value = selectedMyshop.value.name
  }

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(searchText.value) && Boolean(selectedMyshop.value))

  const addToMyshop = () => {
    isSaving.value = true
    dispatch("addToMyshop", {
      idx: router.currentRoute.params.idx,
      myshop_idx: selectedMyshop.value.idx,
    }).then(() => {
      makeToast("primary", "마이스토어에 상품을 담는데 성공했습니다")
      turnOffSidebar()
      getTimelineList()
      fetchSellerList()
    }).catch(() => {
      makeToast("danger", "마이스토어에 상품을 담는데 성공했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleAddMystoreSidebar,
    searchText,
    selectedMyshop,
    turnOnSidebar,
    turnOffSidebar,
    myshopSuggestions,
    inputSearchText,
    selectMyshop,
    isSaving,
    isValid,
    addToMyshop,
  }
}

const optionsTableRef = ref()

export function useProductOption() {
  const optionsWithKey = computed(() => state.optionList.map(o => {
    const optionKeyValues = o.options.reduce((p, c) => ({
      ...p,
      [c.category]: c.name,
    }), {})
    return {
      ...o,
      ...optionKeyValues,
    }
  }))
  const activeOptions = computed(() => optionsWithKey.value.filter(o => o.state === "ACTIVE"))
  const inputOption = (idx, key, value) => {
    state.optionList = state.optionList.map(option => {
      if (option.idx === idx) {
        return {
          ...option,
          [key]: parseInt(value, 10),
        }
      }
      return option
    })
  }

  const calculateAddTagbyFee = optionIdx => {
    const tagbyFeeRate = product.value.tagby_fee_rate
    state.optionList = state.optionList.map(option => {
      if (option.idx === optionIdx) {
        return {
          ...option,
          add_tagby_fee: Math.ceil(option.add_price * (tagbyFeeRate + 0.03), 10),
        }
      }
      return option
    })
  }

  const {
    makeToast,
  } = useToast()

  const getOptionList = () => {
    state.isGettingOption = true
    return api.getOptionList({
      product_idx: state.idx,
    }).then(response => {
      const resData = response.data.data
      state.optionList = resData.option_list.map(o => ({ ...o, state: "ACTIVE", is_saving: false }))
      state.beforeOptionList = resData.option_list.map(o => ({ ...o, state: "ACTIVE", is_saving: false }))
      state.optionMeta = resData.option_meta
      state.optionType = state.optionMeta.type
      state.manageOption.optionCategories = state.optionMeta.categories
      if (state.manageOption.optionCategories.length === 0) {
        state.manageOption.optionCategories = [null]
      }
      state.manageOption.optionNames = state.optionMeta.names.map(names => names.join(","))
      if (state.manageOption.optionNames.length === 0) {
        state.manageOption.optionNames = [null]
      }
      state.manageOption.hasDeletedRows = false
      state.newOptions = null
    }).catch(err => {
      console.log(err)
      makeToast("danger", "옵션목록을 가져오는데 실패했습니다")
    }).finally(() => {
      state.isGettingOption = false
    })
  }

  return {
    options: activeOptions,
    optionsTableRef,
    inputOption,
    calculateAddTagbyFee,
    getOptionList,
  }
}

export function useInputOption() {
  const optionTypeList = [
    { text: "없음", value: "NO" },
    { text: "단독형", value: "SINGLE" },
    { text: "조합형", value: "COMBI" },
  ]
  const getVisibleMinus = () => state.manageOption.optionCategories.length > 1
  const getVisiblePlus = i => state.manageOption.optionCategories.length - 1 <= i
  const appendOptionMeta = () => {
    state.manageOption.optionCategories = state.manageOption.optionCategories.concat([null])
    state.manageOption.optionNames = state.manageOption.optionNames.concat([null])
  }
  const deleteOptionMeta = i => {
    state.manageOption.optionCategories = state.manageOption.optionCategories
      .slice(0, i)
      .concat(state.manageOption.optionCategories.slice(i + 1))
    state.manageOption.optionNames = state.manageOption.optionNames.slice(0, i).concat(state.manageOption.optionNames.slice(i + 1))
  }
  const inputCategory = (value, index) => {
    state.manageOption.optionCategories = state.manageOption.optionCategories.map((v, i) => {
      if (i === index) {
        return value
      }
      return v
    })
  }
  const inputNames = (value, index) => {
    state.manageOption.optionNames = state.manageOption.optionNames.map((v, i) => {
      if (i === index) {
        return value
      }
      return v
    })
  }
  const makeSingleOptions = () => {
    let index = 0
    return state.manageOption.optionNames
      .map(namesWithComma => namesWithComma.split(","))
      .map((nameArray, i) => {
        const category = state.manageOption.optionCategories[i]
        return nameArray.map(name => {
          const settingIdx = index + 1
          index += 1
          return {
            idx: settingIdx,
            product_idx: product.value.idx,
            category,
            name,
            parent_idx: null,
          }
        })
      })
      .reduce((p, c) => p.concat(c), [])
  }
  const makeCombiOptions = () => {
    let index = 0
    const namesList = state.manageOption.optionNames.map(namesWithComma => namesWithComma.split(","))

    const makeCombiOptionsRecursion = (i, parentIdx, name) => {
      const category = state.manageOption.optionCategories[i]
      const nextIndex = index + 1
      index += 1
      if (i === namesList.length - 1) {
        return [{
          idx: index,
          product_idx: product.value.idx,
          category,
          name,
          parent_idx: parentIdx,
        }]
      }
      const nextNames = namesList[i + 1]
      return [{
        idx: index,
        product_idx: product.value.idx,
        category,
        name,
        parent_idx: parentIdx,
      }].concat(nextNames.map(n => makeCombiOptionsRecursion(i + 1, nextIndex, n)))
        .reduce((p, c) => p.concat(c), [])
    }

    return namesList[0].map(name => makeCombiOptionsRecursion(0, null, name))
      .reduce((p, c) => p.concat(c), [])
  }

  const getSyncedOptions = () => {
    const refCount = state.newOptions.reduce((previous, option) => {
      const refCountOfParentIdx = previous[option.parent_idx] ?? 0
      return {
        ...previous,
        [option.parent_idx]: refCountOfParentIdx + 1,
      }
    }, {})
    const optionsByIdx = state.newOptions.reduce((previous, option) => ({
      ...previous,
      [option.idx]: option,
    }), {})
    const getOptionCategoryRecursion = idx => {
      const option = optionsByIdx[idx]
      if (option.parent_idx == null) {
        return [{
          category: option.category,
          name: option.name,
        }]
      }
      return [
        ...getOptionCategoryRecursion(option.parent_idx),
        {
          category: option.category,
          name: option.name,
        },
      ]
    }
    const leafOptions = state.newOptions
      .filter(option => (refCount[option.idx] ?? 0) === 0)
      .map(option => ({
        options: getOptionCategoryRecursion(option.idx),
        add_price: 0,
        add_supply_price: 0,
        add_tagby_fee: 0,
        stock: 0,
        idx: option.idx,
        state: "ACTIVE",
        _next_idx: option.parent_idx,
      }))
    return leafOptions
  }

  const updateOptionsMeta = () => {
    state.optionMeta = {
      type: state.optionType,
      categories: state.manageOption.optionCategories,
      names: state.manageOption.optionNames,
    }

    if (state.optionType === "NO") {
      state.optionList = state.optionList.map(o => ({ ...o, state: "INACTIVE" }))
      state.newOptions = []
    }
    if (state.optionType === "SINGLE") {
      state.newOptions = makeSingleOptions()
    }
    if (state.optionType === "COMBI") {
      state.newOptions = makeCombiOptions()
    }
    state.optionList = getSyncedOptions()
    state.manageOption.hasDeletedRows = true
  }

  return {
    optionTypeList,
    getVisibleMinus,
    getVisiblePlus,
    appendOptionMeta,
    deleteOptionMeta,
    inputCategory,
    inputNames,
    updateOptionsMeta,
  }
}

const visibleDeleteOptionModal = ref(false)
export function useDeleteOptionModal() {
  const turnOn = () => {
    visibleDeleteOptionModal.value = true
  }
  const turnOff = () => {
    visibleDeleteOptionModal.value = false
  }
  const hasSelectedRows = computed(() => {
    if (!optionsTableRef.value) return false
    const { items, selectedRows } = optionsTableRef.value
    const selectedOptionList = items.filter((v, i) => selectedRows[i])
    return selectedOptionList.length > 0
  })

  const deleteOption = () => {
    const { items, selectedRows } = optionsTableRef.value
    const selectedOptionIdxList = items.filter((v, i) => selectedRows[i]).map(r => r.idx)
    state.optionList = state.optionList.map(o => {
      if (selectedOptionIdxList.includes(o.idx)) {
        return {
          ...o,
          state: "INACTIVE",
        }
      }
      return {
        ...o,
      }
    })
    state.manageOption.hasDeletedRows = true
  }
  return {
    visible: visibleDeleteOptionModal,
    hasSelectedRows,
    turnOn,
    turnOff,
    deleteOption,
  }
}

const isSavingOption = ref(false)
export function useUpdateOption() {
  const {
    makeToast,
  } = useToast()
  const hasUpdatedOptionRow = computed(() => bool(state.newOptions) || state.manageOption.hasDeletedRows)

  const {
    getOptionList,
  } = useProductOption()

  const { getTimelineList } = useFetch()

  const updateNewOption = () => {
    isSavingOption.value = true
    return api.updateOption({
      product_idx: state.idx,
      option_leaf_list: state.optionList,
      new_option_meta_list: state.newOptions,
      is_update_stock: state.updateOptionModal.isUpdateStock,
    }).then(() => {
      makeToast("primary", "옵션 수정에 성공했습니다")
      getTimelineList()
      getOptionList()
    }).catch(() => {
      makeToast("danger", "옵션 수정에 실패했습니다")
    }).finally(() => {
      isSavingOption.value = false
    })
  }
  return {
    hasUpdatedOptionRow,
    isSavingOption,
    updateNewOption,
  }
}

export function useUpdateOptionLeaf() {
  const {
    makeToast,
  } = useToast()

  const { getTimelineList } = useFetch()

  const updateOptionLeaf = optionLeaf => {
    const optionItem = state.optionList.find(o => o.idx === optionLeaf.idx)
    optionItem.is_saving = true
    console.log(optionLeaf)
    const beforeOptionLeaf = state.beforeOptionList.find(o => o.idx === optionLeaf.idx)
    console.log(beforeOptionLeaf)
    return api.updateOptionLeaf({
      product_idx: state.idx,
      before_option_leaf: beforeOptionLeaf,
      after_option_leaf: optionLeaf,
    }).then(() => {
      makeToast("primary", "옵션상세 수정에 성공했습니다")
      getTimelineList()
    }).catch(() => {
      makeToast("danger", "옵션상세 수정에 실패했습니다")
    }).finally(() => {
      optionItem.is_saving = false
    })
  }

  return {
    updateOptionLeaf,
  }
}

export function useDetail() {
  const APP_STORE_MODULE_NAME = productStoreModule.STORE_MODULE_NAME

  const { makeToast } = useToast()
  const { resolveUtcDateTime } = useResolveValue()
  const storeDispatch = (name, params) => store.dispatch(`${APP_STORE_MODULE_NAME}/${name}`, params)

  const { getTimelineList } = useFetch()

  const additionalShippingFeeCond = ref({
    jeju: 0,
    island_mountain: 0,
  })
  const shippingFreeCond = ref({
    gte: null,
  })
  const vat = ref({
    is_tax_free: false,
  })
  const abroadJson = ref({
    nation: null,
    acceptor: null,
    contact: null,
  })
  const vendor = ref()
  const hasLoadError = ref(false)
  const isUploadingFile = ref(false)
  const categoryOptions = ref([])
  const shippingPolicy = ref({})

  const tabStage = ref()
  const setTabStage = tab => {
    tabStage.value = tab
    router.replace({
      query: {
        tab: tabStage.value,
      },
    }).catch(() => { })
  }

  const isSavingProduct = ref(false)
  const isSavingStock = ref(false)

  const fetchDetail = () => storeDispatch("fetchDetail", {
    idx: router.currentRoute.params.idx,
  }).then(response => {
    const resData = response.data.data
    state.beforeStock = resData.product.stock
    product.value = resData.product
    vendor.value = resData.vendor
    const constAdditionalShippinFeeCond = product.value.additional_shipping_fee_cond ?? {}
    additionalShippingFeeCond.value = {
      jeju: constAdditionalShippinFeeCond.jeju ?? 0,
      island_mountain: constAdditionalShippinFeeCond.island_mountain ?? 0,
    }
    const constShippingFreeCond = product.value.shipping_free_cond ?? {}
    shippingFreeCond.value = {
      gte: constShippingFreeCond.gte,
    }
    vat.value = {
      is_tax_free: (product.value.vat_rate ?? 0) === 0,
    }
    abroadJson.value = {
      ...abroadJson.value,
      ...(product.value.abroad_json ?? {}),
    }
    state.beforeOptionList = resData.options.map(o => ({ ...o, state: "ACTIVE" }))
    state.optionList = resData.options.map(o => ({ ...o, state: "ACTIVE", is_saving: false }))
    state.optionMeta = resData.options_meta
    state.optionType = state.optionMeta.type
    state.manageOption.optionCategories = state.optionMeta.categories
    if (state.manageOption.optionCategories.length === 0) {
      state.manageOption.optionCategories = [null]
    }
    state.manageOption.optionNames = state.optionMeta.names.map(names => names.join(","))
    if (state.manageOption.optionNames.length === 0) {
      state.manageOption.optionNames = [null]
    }
    shippingPolicy.value = resData.shipping_policy ?? {}
  }).catch(() => {
    hasLoadError.value = true
  }).finally(() => {
  })

  const fetchCategories = () => {
    storeDispatch("fetchCategories")
      .then(response => {
        categoryOptions.value = response.data.data.map(c => ({ label: `${c.class_1} > ${c.class_2}`, value: c.idx }))
      }).catch(() => {
        makeToast("danger", "카테고리 목록을 가져오는데 실패했습니다")
      }).finally(() => {
      })
  }

  const replaceQuillContent = inputString => {
    if (!inputString) {
      return null
    }
    const outputString = inputString.replace(/class="ql-align-(center|justify|right)"/g, (match, value) => `style="text-align: ${value};"`)
    return outputString
  }

  const updateProduct = () => {
    isSavingProduct.value = true
    const params = {
      idx: router.currentRoute.params.idx,
      product: {
        name: product.value.name,
        brand_name: product.value.brand_name,
        category_idx: product.value.category_idx,
        product_no: product.value.product_no,
        sell_type: product.value.sell_type,
        state: product.value.state,
        start_at: resolveUtcDateTime(product.value.start_at),
        end_at: resolveUtcDateTime(product.value.end_at),
        thumbnail_img_url: product.value.thumbnail_img_url,
        detail_html_content: replaceQuillContent(product.value.detail_html_content),
        description: product.value.description,
        supply_price: product.value.supply_price,
        price: product.value.price,
        market_price: product.value.market_price,
        tagby_fee_rate: product.value.tagby_fee_rate,
        is_tax_free: vat.value.is_tax_free,
        shipping_fee: product.value.shipping_fee,
        shipping_free_cond: shippingFreeCond.value,
        max_bundle_count: product.value.max_bundle_count,
        shipping_policy_idx_id: product.value.shipping_policy_idx_id,
        additional_shipping_fee_cond: additionalShippingFeeCond.value,
        min_delivery_days: product.value.min_delivery_days,
        max_delivery_days: product.value.max_delivery_days,
        delivery_content: product.value.delivery_content,
        refund_content: product.value.refund_content,
        is_abroad: product.value.is_abroad,
        abroad_json: abroadJson.value,
        vendor_idx_id: product.value.vendor_idx_id,
      },
      options: state.optionList,
      new_options: state.newOptions,
    }

    storeDispatch("updateProduct", params)
      .then(() => {
        state.newOptions = null
        fetchDetail()
        getTimelineList()
        makeToast("primary", "저장에 성공했습니다.")
      }).catch(() => {
        makeToast("danger", "저장에 실패했습니다.")
      }).finally(() => {
        isSavingProduct.value = false
      })
  }

  const updateStock = () => {
    isSavingStock.value = true
    const params = {
      product_idx: router.currentRoute.params.idx,
      product: {
        stock: product.value.stock,
      },
      options: state.optionList,
      new_options: state.newOptions,
    }

    storeDispatch("updateStock", params)
      .then(() => {
        state.newOptions = null
        fetchDetail()
        getTimelineList()
        makeToast("primary", "저장에 성공했습니다.")
      }).catch(() => {
        makeToast("danger", "저장에 실패했습니다.")
      }).finally(() => {
        isSavingStock.value = false
      })
  }

  const {
    updateNewOption,
  } = useUpdateOption()

  const inputDeliveryDays = value => {
    const [minDay, maxDay] = value.split("~").map(d => {
      const numDay = parseInt(d, 10)
      return Number.isNaN(numDay) ? null : numDay
    })
    product.value.min_delivery_days = minDay
    product.value.max_delivery_days = maxDay
  }

  const memoContent = ref()
  const visibleMemo = ref(false)
  const turnOnMemo = () => {
    visibleMemo.value = true
    memoContent.value = null
  }
  const turnOffMemo = () => {
    visibleMemo.value = false
  }
  const isSavingMemo = ref(false)
  const inputMemoContent = value => {
    memoContent.value = value
  }
  const createMemo = () => {
    isSavingMemo.value = true
    storeDispatch("createMemo", {
      idx: router.currentRoute.params.idx,
      content: memoContent.value,
    }).then(() => {
      makeToast("primary", "메모 생성에 성공했습니다")
      getTimelineList()
    }).catch(() => {
      makeToast("primary", "메모 생성에 성공했습니다")
    }).finally(() => {
      isSavingMemo.value = false
      turnOffMemo()
    })
  }

  const vendorText = ref()
  const vendorList = ref([])
  const fetchVendorList = () => storeDispatch("fetchVendorList", {
    text: vendorText.value,
  }).then(response => {
    vendorList.value = response.data.data
  }).catch(() => {
    makeToast("danger", "벤더 목록을 불러오는데 실패했습니다")
  }).finally(() => {
  })
  const debouncedFetchVendorList = debounce(fetchVendorList, 1000)
  const selectedVendor = ref()
  const visibleVendorChangeSidebar = ref(false)
  const turnOnVendorChangeSidebar = () => {
    vendorText.value = null
    vendorList.value = []
    selectedVendor.value = null
    visibleVendorChangeSidebar.value = true
  }
  const turnOffVendorChangeSidebar = () => {
    visibleVendorChangeSidebar.value = false
  }
  const isValidVendorChange = computed(() => selectedVendor.value != null)
  const inputVendorText = value => {
    vendorText.value = value
    vendorList.value = []
    selectedVendor.value = null
    if (value.length >= 2) {
      debouncedFetchVendorList()
    }
  }
  const selectVendor = data => {
    selectedVendor.value = data.item
  }
  const changeVendor = () => {
    vendor.value = selectedVendor.value
    product.value.vendor_idx_id = selectedVendor.value.idx
    product.value.shipping_policy_idx_id = null
    shippingPolicy.value = {}
    additionalShippingFeeCond.value = {
      jeju: 0,
      island_mountain: 0,
    }
    turnOffVendorChangeSidebar()
  }

  const shippingPolicyList = ref([])
  const fetchShippingPolicyList = () => storeDispatch("fetchShippingPolicyList", {
    idx: router.currentRoute.params.idx,
  }).then(response => {
    shippingPolicyList.value = response.data.data
  }).catch(() => {
    makeToast("danger", "합배송그룹을 불러오는데 실패했습니다")
  }).finally(() => {
  })
  const visibleShippingPolicyChangeSidebar = ref(false)
  const turnOnShippingPolicyChangeSidebar = () => {
    shippingPolicyList.value = []
    fetchShippingPolicyList()
    visibleShippingPolicyChangeSidebar.value = true
  }
  const turnOffShippingPolicyChangeSidebar = () => {
    visibleShippingPolicyChangeSidebar.value = false
  }
  const changeShippingPolicy = item => {
    product.value.shipping_policy_idx_id = item.idx
    shippingPolicy.value = item
    additionalShippingFeeCond.value = {
      jeju: item.additional_shipping_fee_cond?.jeju ?? 0,
      island_mountain: item.additional_shipping_fee_cond?.island_mountain ?? 0,
    }
    turnOffShippingPolicyChangeSidebar()
  }

  const visibleShippingPolicyDeleteModal = ref(false)
  const turnOnShippingPolicyDeleteModal = () => {
    visibleShippingPolicyDeleteModal.value = true
  }
  const turnOffShippingPolicyDeleteModal = () => {
    visibleShippingPolicyDeleteModal.value = false
  }
  const deleteShippingPollicy = () => {
    product.value.shipping_policy_idx_id = null
    shippingPolicy.value = {}
    additionalShippingFeeCond.value = {
      jeju: 0,
      island_mountain: 0,
    }
  }

  const imageCropModal = ref()
  const processingImageCrop = computed(() => imageCropModal.value?.processing ?? false)
  const wakeUpImageCropModal = () => {
    imageCropModal.value.wakeUp()
  }
  const changeImageUrl = imageUrl => {
    console.log(imageUrl)
    product.value.thumbnail_img_url = imageUrl
  }

  const selectedOption = ref({
    idx: null,
    add_supply_price: null,
    add_price: null,
    add_tagby_fee: null,
    stock: null,
  })
  const visibleOptionUpdateSidebar = ref(false)
  const turnOnOptionUpdateSidebar = item => {
    visibleOptionUpdateSidebar.value = true
    selectedOption.value = {
      idx: item.idx,
      add_supply_price: item.add_supply_price,
      add_price: item.add_price,
      add_tagby_fee: item.add_tagby_fee,
      stock: item.stock,
    }
  }
  const turnOffOptionUpdateSidebar = () => {
    visibleOptionUpdateSidebar.value = false
    selectedOption.value = {
      idx: null,
      add_supply_price: null,
      add_price: null,
      add_tagby_fee: null,
      stock: null,
    }
  }
  const isSavingOptionUpdate = ref(false)
  const isValidOptionUpdate = computed(() => (
    selectedOption.value.add_supply_price != null
    && selectedOption.value.add_price != null
    && selectedOption.value.add_tagby_fee != null
    && selectedOption.value.stock != null))

  const updateOption = item => {
    isSavingOptionUpdate.value = true
    const optionIdx = item.idx
    state.optionList = state.optionList.map(o => {
      if (o.idx === optionIdx) {
        return {
          ...o,
          ...item,
        }
      }
      return {
        ...o,
      }
    })
    isSavingOptionUpdate.value = false
    turnOffOptionUpdateSidebar()
  }

  return {
    product,
    additionalShippingFeeCond,
    shippingFreeCond,
    vat,
    abroadJson,
    vendor,
    hasLoadError,
    isUploadingFile,
    tabStage,
    categoryOptions,
    shippingPolicy,
    isSavingProduct,
    isSavingStock,
    isSavingOption,
    updateStock,
    updateOption,
    fetchDetail,
    getTimelineList,
    fetchCategories,

    setTabStage,
    updateProduct,
    inputDeliveryDays,

    memoContent,
    visibleMemo,
    turnOnMemo,
    turnOffMemo,
    isSavingMemo,
    inputMemoContent,
    createMemo,

    vendorText,
    vendorList,
    selectedVendor,
    visibleVendorChangeSidebar,
    turnOnVendorChangeSidebar,
    turnOffVendorChangeSidebar,
    isValidVendorChange,
    inputVendorText,
    selectVendor,
    changeVendor,

    shippingPolicyList,
    visibleShippingPolicyChangeSidebar,
    turnOnShippingPolicyChangeSidebar,
    turnOffShippingPolicyChangeSidebar,
    changeShippingPolicy,

    visibleShippingPolicyDeleteModal,
    turnOnShippingPolicyDeleteModal,
    turnOffShippingPolicyDeleteModal,
    deleteShippingPollicy,

    imageCropModal,
    processingImageCrop,
    wakeUpImageCropModal,
    changeImageUrl,

    selectedOption,
    visibleOptionUpdateSidebar,
    turnOnOptionUpdateSidebar,
    turnOffOptionUpdateSidebar,
    isSavingOptionUpdate,
    isValidOptionUpdate,
    updateNewOption,

    visibleDeleteOptionModal,
    // turnOnDeleteOptionModal,
    // turnOffDeleteOptionModal,
    // deleteOption,
  }
}

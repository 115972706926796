<template>
  <TagbySidebar
    :visible="visibleAddMystoreSidebar"
    title="마이스토어 상품 담기"
    @hidden="turnOffSidebar"
  >
    <template #content>
      <BFormGroup>
        <VueAutosuggest
          :value="searchText"
          :suggestions="myshopSuggestions"
          :limit="100"
          :input-props="inputProps"
          @input="inputSearchText"
          @selected="selectMyshop"
        >
          <template slot-scope="{suggestion}">
            <div class="d-flex align-items-center">
              <div class="detail ml-50">
                <BCardText class="mb-0">
                  {{ suggestion.item.name }} ({{ suggestion.item.url_path }})
                </BCardText>
                <small class="text-muted">
                  {{ suggestion.item.inf.name }}
                </small>
              </div>
            </div>
          </template>
        </VueAutosuggest>
      </BFormGroup>
      <BCard
        class="border"
        no-body
      >
        <pre>{{ JSON.stringify(selectedMyshop, null, 4) }}</pre>
      </BCard>
      <TagbySidebarButtons
        :isSaving="isSaving"
        :isValid="isValid"
        @ok="addToMyshop"
        @cancel="turnOffSidebar"
      />
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BCardText,
  BCard,
  BRow,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import TagbySidebar from '@/components/TagbySidebar.vue'
import TagbySidebarButtons from '@/components/TagbySidebarButtons.vue'
import { useAddMystore } from '../viewModel'

export default defineComponent({
  components: {
    BFormGroup,
    BCardText,
    BCard,
    BRow,
    VueAutosuggest,
    TagbySidebar,
    TagbySidebarButtons,
  },
  setup() {
    const {
      visibleAddMystoreSidebar,
      searchText,
      selectedMyshop,
      myshopSuggestions,
      turnOffSidebar,
      inputSearchText,
      selectMyshop,
      isSaving,
      isValid,
      addToMyshop,
    } = useAddMystore()

    const inputProps = {
      id: 'autosuggest__input',
      class: 'form-control',
      placeholder: '인플루언서 idx, name 또는 마이스토어 name, url_path를 입력해주세요',
    }

    return {
      visibleAddMystoreSidebar,
      searchText,
      selectedMyshop,
      myshopSuggestions,
      turnOffSidebar,
      inputSearchText,
      selectMyshop,
      isSaving,
      isValid,
      addToMyshop,
      inputProps,
    }
  },
})
</script>

<style lang="scss" scoped>
pre{
  min-height: 200px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: .5rem;
}
</style>

<template>
  <div>
    <TagbySidebar
      :visible="visible"
      width="30%"
      @hidden="$emit('hidden')"
    >
      <template #title>
        벤더 변경
      </template>
      <template #content>
        <BRow>
          <BCol>
            <BFormGroup>
              <VueAutosuggest
                :value="vendorText"
                :suggestions="vendorSuggestion"
                :limit="100"
                :input-props="inputProps"
                @input="$emit('inputVendorText', $event)"
                @selected="$emit('selectVendor', $event)"
              >
                <template slot-scope="{suggestion}">
                  <div class="d-flex align-items-center">
                    <div class="detail ml-50">
                      <BCardText class="mb-0">
                        {{ suggestion.item.name }} ({{ suggestion.item.company_name }})
                      </BCardText>
                      <small class="text-muted">
                        {{ suggestion.item.email }}
                      </small>
                    </div>
                  </div>
                </template>
              </VueAutosuggest>
            </BFormGroup>
          </BCol>
        </BRow>
        <div v-if="selectedVendor != null">
          <h4>
            정보
          </h4>
          <BRow>
            <BCol>
              <BFormGroup label="IDX">
                <BFormInput
                  :value="selectedVendor.idx"
                  readonly
                />
              </BFormGroup>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <BFormGroup label="이름">
                <BFormInput
                  :value="selectedVendor.name"
                  readonly
                />
              </BFormGroup>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <BFormGroup label="회사명">
                <BFormInput
                  :value="selectedVendor.company_name"
                  readonly
                />
              </BFormGroup>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <BFormGroup label="이메일">
                <BFormInput
                  :value="selectedVendor.email"
                  readonly
                />
              </BFormGroup>
            </BCol>
          </BRow>
        </div>
        <BRow>
          <BCol>
            <BButton
              variant="outline-primary"
              class="mr-1"
              :disabled="!isValid"
              @click="turnOnModal"
            >
              <BSpinner
                v-if="isSaving"
                small
              />
              확인
            </BButton>
            <BButton
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              취소
            </BButton>
          </BCol>
        </BRow>
      </template>
    </TagbySidebar>
    <BModal
      ref="vendorChangeModal"
      title="벤더 변경"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      centered
      @ok="$emit('ok')"
      @hidden="turnOffModal"
    >
      벤더를 변경하시겠습니까? 합배송그룹이 초기화 됩니다.
    </BModal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BCardText,
  BModal,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  ref,
  computed,
} from '@vue/composition-api'
import TagbySidebar from '@/components/TagbySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCardText,
    BModal,

    VueAutosuggest,
    TagbySidebar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    vendorText: {
      type: String,
      default: null,
    },
    vendorList: {
      type: Array,
      default: () => ([]),
    },
    selectedVendor: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const vendorSuggestion = computed(() => ([{
      data: props.vendorList,
    }]))

    const inputProps = {
      id: 'autosuggest__input',
      class: 'form-control',
      placeholder: '벤더 이름/회사명/이메일 입력 (2자 이상)',
    }

    const isSaving = ref(false)
    const vendorChangeModal = ref()
    const turnOnModal = () => {
      isSaving.value = true
      vendorChangeModal.value.show()
    }
    const turnOffModal = () => {
      isSaving.value = false
    }

    const test = value => { console.log(value) }
    return {
      vendorSuggestion,
      inputProps,
      test,

      isSaving,
      vendorChangeModal,
      turnOnModal,
      turnOffModal,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<template>
  <TagbySidebarV2
    :isVisible="state.uploadOptionExcelSidebar.isVisible"
    :isSaving="state.uploadOptionExcelSidebar.isUploading"
    :isValid="isValid"
    @hidden="turnOffSidebar"
    @ok="uploadOptionExcel"
  >
    <BFormGroup label="엑셀업로드">
      <BFormFile v-model="state.uploadOptionExcelSidebar.excelFile" />
    </BFormGroup>
  </TagbySidebarV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormGroup,
  BFormFile,
} from "bootstrap-vue"
import TagbySidebarV2 from "@/components/TagbySidebarV2.vue"
import TagbyOverlay from "@/components/TagbyOverlay.vue"
import state from "../../state"
import useUploadOptionExcelSidebar from "../../hooks/useUploadOptionExcelSidebar"

export default defineComponent({
  components: {
    BFormGroup,
    BFormFile,
    TagbySidebarV2,
    TagbyOverlay,
  },
  setup() {
    const {
      isValid,
      turnOffSidebar,
      uploadOptionExcel,
    } = useUploadOptionExcelSidebar()

    return {
      state,
      isValid,
      turnOffSidebar,
      uploadOptionExcel,
    }
  },
})
</script>

import axios from "@axios"

const STORE_MODULE_NAME = "product"

const actions = {
  fetchDetail(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchTimelines(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/timelines/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchCategories() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/categories/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  updateProduct(ctc, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/update/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  createMemo(ctc, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/memo/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchVendorList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/vendors/`, { params })
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchShippingPolicyList(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/shipping-policies/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchSellerList(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/seller/`)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  fetchMyshopList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/myshops/`, { params })
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  addToMyshop(ctx, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/${idx}/add-to-myshop/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  updateStock(ctc, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/update-stock/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
  updateOption(ctc, { idx, ...params }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/product/update-option/`, params)
        .then(response => { resolve(response) })
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}

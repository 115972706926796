<template>
  <BModal
    :visible="visible"
    title="합배송그룹 제거"
    ok-title="확인"
    ok-variant="primary"
    cancel-title="취소"
    cancel-variant="outline-secondary"
    centered
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    합배송그룹을 제거하시겠습니까?
  </BModal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    BModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  },
})
</script>

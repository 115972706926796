import {
  computed,
  reactive,
} from "@vue/composition-api"

import state from "./state"
import {
  bool,
} from "@/utils/value"

const isCreatingMode = computed(() => !state.idx)

const hasOption = computed(() => bool(state.optionList))

export default reactive({
  isCreatingMode,
  hasOption,
})

<template>
  <BModal
    v-model="visible"
    title="옵션 삭제"
    ok-title="확인"
    ok-variant="danger"
    cancel-title="취소"
    cancel-variant="outline-secondary"
    centered
    modal-class="modal-danger"
    @ok="deleteOption"
  >
    옵션을 제거하시겠습니까?
  </BModal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import { useDeleteOptionModal } from '../viewModel'

export default defineComponent({
  components: {
    BModal,
  },
  setup() {
    const {
      visible,
      deleteOption,
    } = useDeleteOptionModal()

    return {
      visible,
      deleteOption,
    }
  },
})
</script>
